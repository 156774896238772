/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
var site = function () {

    this.hWindow = $(window).height();


    this.global = function () {

        $('[data-toggle="tooltip"]').tooltip();
        $('.chocolat-parent').Chocolat();

        if (typeof WOW === "function") {
            var wow = new WOW({
                boxClass: 'wow',        // animated element css class (default is wow)
                animateClass: 'animated',   // animation css class (default is animated)
                offset: 0,            // distance to the element when triggering the animation (default is 0)
                mobile: true,         // trigger animations on mobile devices (default is true)
                live: true          // act on asynchronously loaded content (default is true)
            });
            wow.init();
        }
    }

    this.ajustWindowHeight = function (elem, diffBottom) {
        if (this.hWindow >= 390) {
            elem.css('height', this.hWindow - diffBottom);
        }
    }

    this.matchHeight = function () {
        $('.match-height').matchHeight({ byRow: false });
    };

    this.configForm = function () {
        $('.mask-phone').mask('(00)000000009');
        $('.mask-date').mask('00/00/0000');
        $('.mask-schedule').mask('00:00');
        $('.mask-cpf').mask('000.000.000-00');
        $('.mask-cnpj').mask('00.000.000/0000-00');
        $('.mask-cep').mask('00000-000');
        $('.mask-money').mask('#.##0,00', { reverse: true });
        $('.custom-file-input').on('change', function () {
            var fileName = document.getElementById("f-attachment").files[0].name;
            $('.custom-file-label').text(fileName);
        })
    }

    /*
    this.loadImages = function () {
        $('.load-image').each(function () {
            var elem = $(this);
            var cls = elem.data('class');
            cls = (cls === undefined) ? '' : cls;
            Modernizr.on('webp', function (result) {
                if (result) {
                    var bg = elem.data('bg');
                    if (undefined == bg) {
                        var webp = elem.data('webp');
                        if (undefined != webp) {
                            elem.append('<img alt="" title="" src="' + webp + '" class="img-fluid ' + cls + '" />');
                        } else {
                            var src = elem.data('src');
                            elem.append('<img alt="" title="" src="' + src + '" class="img-fluid ' + cls + '" />');
                        }
                    } else {
                        var webp = elem.data('webp');
                        if (undefined !== webp) {
                            elem.css('background-image', 'url(' + webp + ')');
                        } else {
                            elem.css('background-image', 'url(\"' + bg + '\")')
                        }
                    }
                } else {
                    var bg = elem.data('bg');
                    if (undefined === bg) {
                        var src = elem.data('src');
                        elem.append('<img alt="" title="" src="' + src + '" class="img-fluid ' + cls + '" />');
                    } else {
                        elem.css('background-image', 'url(\"' + bg + '\")')
                    }
                }
            });
        });
    };
    */

    this.mainSlider = function () {
        var mySwiper = new Swiper('.swiper-main', {
            loop: true,
            fadeEffect: {
                crossFade: true
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.swiper-pagination-main',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        });
    };

    this.bannersSlider = function () {
        var mySwiper = new Swiper('.main-banner', {
            loop: false,
            effect: 'fade',
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
            },
            pagination: {
                el: '.main-banner-pagination',
                clickable: true
            },
        });
    };

    this.partnersSlider = function () {
        var galleryThumbs = new Swiper('.main-partners .swiper-container', {
            spaceBetween: 50,
            slidesPerView: 4,
            slidesPerGroup: 4,
            pagination: {
                el: '.partners-swiper-pagination',
                clickable: true
            },
            breakpoints: {
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4,
                    spaceBetween: 50,
                },
                768: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                    spaceBetween: 30,
                },
                640: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    slidesPerGroup: 1,
                    spaceBetween: 10,
                }
            }
        });
    };

    this.vetorMapBrazil = function () {
        if($('#map-brazil').length){
            $('#map-brazil').vectorMap({
                map: 'brazil_br',
                color: '#17305c',
                hoverColor: '#1b6be0',
                borderColor: '#fff',
                showLabels: true,
                enableZoom: false,
                onRegionClick: function (element, code, region) {
                    window.location.href = code.toLowerCase() + '/';
                }
            });
        }
    }

    this.validate = function () {
        var objThis = this;
        if (typeof $.fn.validate === "function") {
            const formContact = $('#frm-contact');
            if (formContact.length) {
                formContact.validate({
                    submitHandler: function () {
                        objThis.send(formContact, 'send');
                    },
                    rules: {
                        txt_name: "required",
                        txt_email: "required",
                        txta_message: "required"
                    },
                    messages: {
                        txt_name: "Informe seu nome",
                        txta_message: "Deixe sua mensagem",
                        txt_email: "Informe seu e-mail",
                    }
                });
            };

            const formContactInstructor = $('#frm-contact-instructor');
            if (formContactInstructor.length) {
                formContactInstructor.validate({
                    submitHandler: function () {
                        objThis.send(formContactInstructor, 'instructor');
                    },
                    rules: {
                        txt_name: "required",
                        txt_phone: "required",
                        txt_email: "required",
                        txt_cpf: "required",
                        txt_street: "required",
                        txta_regions: "required",
                        chk_sport: "required",
                        chk_term: "required"
                    },
                    messages: {
                        txt_name: "Informe seu nome",
                        txt_phone: "Informe seu telefone",
                        txt_email: "Informe seu e-mail",
                        txt_cpf: "Informe seu CPF",
                        txt_street: "Informe o endereço",
                        txta_regions: "Informe a região",
                        chk_sport: "Informe a modalidade",
                        chk_term: "Aceite os termo de consentimento",
                    }
                });
            };

            const formNewsletter = $('#frm-newsletter');
            if (formNewsletter.length) {
                formNewsletter.validate({
                    submitHandler: function () {
                        objThis.send(formNewsletter, 'ajax_mailer_record');
                    },
                    rules: {
                        txt_newsletter_email: {
                            required: true,
                            email: true
                        }
                    },
                    messages: {
                        txt_newsletter_email: {
                            required: "Informe seu e-mail",
                            email: "E-mail Inválido"
                        }
                    }
                });
            };
        }
    };

    this.send = function (form, uri) {
        $('.msg-registration').remove();
        $('button.send', form).prop('disabled', true);
        var data = form.serialize();
        var labelButtomDefault = $('button.send', form).html();
        $('button.send', form).html('Aguarde!...');
        $.ajax({
            url: base_url + 'registration/' + uri,
            type: 'POST',
            data: data,
            success: function (data) {
                console.log(data);
                $('.msg-registration').remove();
                try {
                    var r = JSON.parse(data);
                    if ('success' === r._return) {
                        form[0].reset();

                        var formId = form.attr('id');
                        /*
                        if (formId == 'frm-contact') {
                            gtag('event', 'conversion', {'send_to': 'AW-941751029/jN4xCMiY4l4Q9fWHwQM'});
                        }
                        if (formId == 'frm-uce') {
                            gtag('event', 'conversion', {'send_to': 'AW-941751029/wXSYCNi1vHIQ9fWHwQM'});
                        }
                         */
                        $('button.send', form).prop('disabled', false).html(labelButtomDefault);
                        form.append('<div class="msg-registration success">' + r._msg + '</div>');
                    } else {
                        form.append('<div class="msg-registration error">Ops! algo deu errado.</div>');
                        $('button.send', form).prop('disabled', false).html(labelButtomDefault);
                    }
                } catch (e) {
                    form.append('<div class="msg-registration error">Ops! algo deu errado.</div>');
                    $('button.send', form).prop('disabled', false).html(labelButtomDefault);
                }
            },
            error: function () {
                $('.msg-registration').remove();
                $('button', form).append('<div class="msg-registration error">Ops! algo deu errado.</div>');
                $('button', form).prop('disabled', false).html(labelButtomDefault);
            }
        });
        return false;
    };
};

var objSite = new site();
$(window).on('load', function () {
    //objSite.loadImages();
    objSite.global();
    $('body').fadeIn(800, function () {
        objSite.mainSlider();
        objSite.bannersSlider();
        objSite.partnersSlider();
        objSite.vetorMapBrazil();
    });
});

$(window).on('resize', function () {
    objSite.hWindow = $(window).height();
    objSite.ajustWindowHeight($('.main-banner'), 60);
});

$(function () {
    objSite.ajustWindowHeight($('.main-banner'), 60);
    objSite.matchHeight();
    objSite.configForm();
    objSite.validate();
});